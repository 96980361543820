
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import dayjs from "dayjs";
import FileUpload from "@/components/scope/file-upload.vue";
import SwitchStatus from "@/components/scope/switch-status.vue";

const parts = namespace("parts");
const user = namespace("user");
const app = namespace("app");
const base = namespace("base");
const category = namespace("service-category");
@Component({
  components: { SwitchStatus, FileUpload }
})
export default class ServiceItemEdit extends Vue {
  @app.Mutation setPBPX;

  @user.State userData;
  @base.Action getDictionaryList;

  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;

  @category.Action createServiceCategory;
  @category.Action updateServiceCategory;
  @category.Action serviceCategoryDetail;
  dayjs = dayjs;
  splitThousands = splitThousands;

  defaultIconList: any = [];

  formData: any = {
    serviceCategoryCode: "",
    serviceCategoryName: "",
    serviceClass: "",
    iconUrl: "",
    status: "",
    businessTypes: []
  };

  get isAdd() {
    return this.$route.name === "serviceCategoryAdd";
  }

  get categoryCode() {
    return this.$route.params.categoryCode;
  }

  get breadData() {
    return [
      { name: '服务分类', path: "/management/service-category" },
      {
        name: this.isAdd ? '添加服务分类' : '编辑服务分类'
      }
    ];
  }

  formDataRules = {
    serviceCategoryName: [{ required: true, message: "请输入服务分类名称", trigger: "change" }],
    iconUrl: [{ required: true, message: "请上传图标", trigger: "blur" }],
    serviceClass: [{ required: true, message: "请选择服务类型", trigger: "blur" }],
    businessTypes: { required: true,  type:"array", min:1, message:"请添加适用门店", trigger:['change', 'blur']},
  };

  binStoreTypeFormRules: any = {
    businessType: [
      { required: true, message:"请选择门店类型"}
    ]
  }

  handleChangeBusinessType(val){
    this.disableStoreType()
  }

  disableStoreType() {
    let usedType = [];
    this.formData.businessTypes.forEach(businessTypes => {
      usedType.push(businessTypes.businessType + "");
    });
    this.storeTypesList.forEach(item => {
      item.disabled = false;
      if (usedType.indexOf(item.value) > -1) {
        item.disabled = true;
      }
    });
  }

  businessTypeDefined: any = {
    businessType: "",
    isShow: 0
  }
  handleAddStoreType(){
    this.formData.businessTypes.push(Object.assign({}, this.businessTypeDefined, ));
  }
  handleRemoveStoreType(index){
    this.formData.businessTypes.splice(index, 1);
    this.disableStoreType();
  }

  emptyToLine = emptyToLine;


  created() {
    this.setPBPX(52);
    this.init();
  }

  serviceTypeList: any = [];
  storeTypesList: any = [];

  async init() {
    this.getDictionaryList("service_class").then(data => {
      this.serviceTypeList = data;
    });
    this.getDictionaryList("store_type").then(data => {
      this.storeTypesList = data;
    });
    if(this.categoryCode) {
      this.serviceCategoryDetail({ categoryCode: this.categoryCode }).then(res => {
        Object.keys(res.data).forEach(key => {
          this.formData[key] = res.data[key];
        });
        this.defaultIconList = [{ url: res.data['iconUrl'] }];
        this.disableStoreType();
      });
    }
  }
  submitForm(formName) {
    (this.$refs.serviceCategoryForm as any).validate(valid => {
      if (valid) {
        if (this.isAdd) {
          this.addService();
        } else {
          this.updateService();
        }
      } else {
        console.log("error submit!!");
      }
    });
  }
  addService() {
    this.btnStartLoading();
    this.createServiceCategory(this.formData)
      .then(data => {
        this.$message.success(this.$t("base.success") as any);
        this.$router.go(-1);
      })
      .finally(item => {
        this.btnStopLoading();
      });
  }

  updateService() {
    this.btnStartLoading();
    this.updateServiceCategory(this.formData)
      .then(data => {
        this.$message.success(this.$t("base.success") as any);
        this.$router.go(-1);
      })
      .finally(item => {
        this.btnStopLoading();
      });
  }

}
